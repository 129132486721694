import React, { useState, useRef } from "react";

export default function Mint(props) {
  const [assetURIs, setAssetURIs] = useState([]);

  const CheckAssetURIs = async () => {
    let uris = [];

    for (let idx = 1; idx <= 1; idx++) {
      let uri = "/token_data/exobit_" + idx + ".json";
      let tokenId = "0"; 
      if (tokenId === "0") uris.push(uri);
    }

    if (uris.length) setAssetURIs([...uris]);
  };

  const BaseUri = async () => {
    let result = await props.contract.methods
      .baseURI()
      .call({
        from: props.address,
      })
      .then((result) => {
        document.getElementById("baseURL").value = result;
      });
  };

  const DoMint = async (tokenURI) => {
    console.log("minting: ", tokenURI);
    try {
      let result = await props.contract.methods.mintTokenFromWeb(1).send({
        from: props.address,
        value: 50000000000000000,
        gasLimit:200000,
      });

      console.log("result", result);

      CheckAssetURIs();
    } catch (e) {
      console.error("There was a problem while minting", e);
    }
  };

  const SendNFT = async () => {
    const elID = document.getElementById("nftID").value;
    const elAddr = document.getElementById("toAddr").value;

    let result = await props.contract
      .safeTransferFrom(props.address, elAddr, props.web3.utils.toBN(elID))
      .send({
        from: props.address,
      });

    console.log("result", result);
  };

  if (!props.contract)
    return <div className="page error">Contract Not Available</div>;

  if (!assetURIs.length) CheckAssetURIs();

  return (
    <div className="page mint">
      <h2>Mint your NFT</h2>
      {assetURIs.map((uri, idx) => (
        <div onClick={() => DoMint(uri)} key={idx}>
          <input type="button" value="Mint NFT" />
        </div>
      ))}
      <h2>Send NFT to your friend</h2>
      <input type="text" id="nftID" defaultValue="1" />
      <input
        type="text"
        id="toAddr"
        defaultValue="0xa55f1055f0cB1dC347a1F4536002BE138612EC46"
      />
      <div onClick={() => SendNFT()}>
        <input type="button" value="Send NFT" />
      </div>
      <h2>NFT Base URI</h2>
      <input id="baseURL" value={BaseUri()} />
    </div>
  );
}
