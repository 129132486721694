import React, { useEffect, useState } from "react";
import parse from 'html-react-parser';

export default function Gallery(props) {
  const [totalSupply, setTotalSupply] = useState(null);
  const [tokenURIs, setTokenURIs] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [tokenIdCounter, setTokenIdCounter] = useState([]);
  const [galerieImagesHTML, setGalerieImagesHTML] = useState("");

  const GetTotalSupply = async () => {
    if (!props || !props.contract) return;
    const totalSupply = await props.contract.methods.totalSupply().call();
    const tokenIdCounter = await props.contract.methods.tokenIdCounter().call();
    setTotalSupply(parseInt(totalSupply));
    setTokenIdCounter(parseInt(tokenIdCounter));
  };

  const GetTokenURIs = async (tokenIdCounter) => {
    if (!tokenIdCounter) return;
    tokenIdCounter--;
    let tokens = [];
    for (let idx = 1; idx <= tokenIdCounter; idx++) {
      try {
        let tokenURI = await props.contract.methods.tokenURI(idx).call();
        let response = await fetch(tokenURI);
        let metaData = await response.json();
        if (metaData && metaData.image) tokens.push(metaData.image);
      } catch (e) {
        if(e.message.startsWith("execution reverted: ERC721: invalid token ID")) break;
        continue;
      }
    }

    if (tokens.length)
    { 
      setTokens(tokens);
      setTokenURIs([...tokens]);
    }
  };

  if (!props.contract)
    return <div className="page error">Contract Not Available</div>;

  if (!totalSupply) GetTotalSupply();

  if (tokenIdCounter && !tokenURIs.length) GetTokenURIs(tokenIdCounter);

  if(tokenURIs.length && !galerieImagesHTML)
  {
      let col = 0;
      let row = 0;
      let galerieImagesHTML = "";

      for (let index = 0; index < tokenURIs.length; index++) {
        const url = tokenURIs[index];
        galerieImagesHTML += "<img class='galeryImage' src='" + url + "'>";
      }

      setGalerieImagesHTML(galerieImagesHTML);
  }

  return (
    <div className="page gallery">
      <h2>Gallery Page</h2>
      <br />
      Total Supply: {totalSupply}
      <br />
      <div dangerouslySetInnerHTML={{__html: galerieImagesHTML}}>
        
      </div>
    </div>
  );
}
