import React from "react";
import Web3 from "web3";

import TestCollectionABI from "../contract/BATS.json";

export default function Login(props) {
  const contractAddress = "0xA5fEf0b8d5Bc6F4288251FBDe6b9A52D5eebD5a0";

  const DoConnect = async () => {
    console.log("Connecting....");
    try {
      const web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const accounts = await web3.eth.getAccounts();
      const instance = new web3.eth.Contract(
        TestCollectionABI,
        contractAddress
      );
      props.callback({ web3, accounts, contract: instance });
    } catch (error) {
      console.error("Could not connect to wallet.", error);
    }
  };

  if (!props.connected)
    return (
      <button className="login" onClick={DoConnect}>
        Connect Wallet
      </button>
    );

  return <>[{props.address.slice(0, 6)}]</>;
}
