import React from "react";

export default function Home() {
	return (
		<div className="page home">
			<div class="head">
				<div class="rect1"></div>
				<div class="rect2"></div>
				<div class="tenisplace"></div>
			</div>
			<div class="roadmap">
				<h1 class="centralText">
					Roadmap
				</h1>
			</div>
		</div>
	);
}